'use client'
import React from 'react'
import { useSearchParams, usePathname, useParams } from 'next/navigation'
// App
import { Loader } from '@/app/loading'
// Compositions
import { SectionElement } from '@/core/compositions/articleSection'
// Elements
import TypographyElement from '@/core/elements/typography'
import Field from '@/core/elements/fields'
// Modules
import DynamicContent from '@/core/modules/dynamicContent'
// Utils
import { buildLocalHost } from '@/core/utils/buildHost'
import { useFilterState } from '@/core/filterContext'
// Section related
import { ToggleButton } from '../../views/styles'
import { MapView } from '../../views/view'
import MainContent from '../../mainContent'
import {
  orderOptions,
  callToActionMap,
  getDynamicIntro,
} from '../../utils'

// Main module
const ClubResults = ({
    intro_text,
    cta,
    body,
    ctaVariant = 'colored',
    ctaPosition,
    isLocation = false,
    get_filters: mainFilters,
    get_global_attributes: attributeFilters,
    perPage = 6,
}) => {
    const [tab, setTab] = React.useState('list')
    const [loading, setLoading] = React.useState(true)
    const [mounted, setMounted] = React.useState(false)
    const [previousPath, setPreviousPath] = React.useState('')

    const {
        getInitialData,
        getData,
        filterState,
        setPagetype,
        setHost,
        setCategory,
        setFilters,
        setAdditionals,
        setAttributes,
        setDistancePostalcode,
        setOrder,
        setPage,
        setMode,
        clubs,
        locations,
        isLoading,
        handleOrderChange,
        handlePageChange,
        handleModeChange,
        filterOpenMobile,
        setMobileOpen,
    } = useFilterState()

    const {
        page,
        order,
        category,
        filters,
        attributes: selectedAttributes,
        additionals: selectedAdditionals,
        dynamicContent,
        distance,
        postalCode,
    } = filterState

    const searchParams = useSearchParams()
    const pars = useParams()

    // Get a dict of all searchParams
    const query = {}
    searchParams.forEach((value, key) => {
        let newVal = value
        if (key in query) {
            let prev = query[key]
            if (typeof prev === "string") {
                newVal = [prev, value]
            } else {
                newVal = [...prev, value]
            }
        }
        query[key] = newVal
    })

    const pathname = usePathname()
    const params = new URLSearchParams(searchParams)
    const asPath = `${pathname}?${params.toString()}`

    const host = React.useMemo(() => {
        return buildLocalHost()
    }, [])

    const { title, text } = React.useMemo(() => {
        return (intro_text && intro_text[0] && intro_text[0].value) || {}
    }, [intro_text])
    const extraContent = React.useMemo(() => {
        return body && body[0] && body[0].value
    }, [body])
    const ctaData = React.useMemo(() => {
        return cta?.length > 0 && {
            title: cta[0].value?.link?.text,
            href: cta[0].value?.link?.href || cta[0].value?.link?.external_url,
            img: cta[0].value?.image,
        }
    }, [cta])

    const getSelectedAttributes = (attributes) => {
        // Get the attribute objects from the strings in url
        const selected = []
        attributeFilters?.forEach((group) => {
            if (
                !group?.attributes?.some((attr) => {
                  return attributes.includes(`${attr.id}`)
                })
            ) {
                // If no match, skip the entire group
                return
            }
            group.attributes.forEach((attribute) => {
                const attributeId = attribute.id.toString()
                if (attributes.includes(attributeId)) {
                    selected.push(attribute)
                }
            })
        })
        return selected
    }

    const getFiltersAndSubfilters = (category, subfilters) => {
        // Get the category and filter objects from the strings in url
        let selectedCategory = null
        const selectedFilters = []

        if (typeof subfilters == 'string') {
          subfilters = [subfilters]
        }

        mainFilters?.filters.forEach((filter) => {
            if (filter.slug == category) {
                selectedCategory = filter
            }
            if (!subfilters) {
                return
            }
            selectedCategory?.subfilters.forEach((item) => {
                if (
                    subfilters?.includes(item.slug) &&
                    !selectedFilters.some((subfilter) => subfilter.id == item.id)
                ) {
                    selectedFilters.push(item)
                }
            })
        })
        return [selectedCategory, selectedFilters]
    }

  React.useEffect(() => {
    // getInitial data using filter / sports from url
    const pagetype = isLocation ? 'location' : 'club'
    let selectedFilters = []
    let queryAdditionals = []
    let queryAttributes = []
    let queryDistance = null
    let queryMode = ''
    let selectedCategory = null

    const urlCategory = pars?.category
    const urlSports = pars?.sports

    if (urlCategory || urlSports) {
      // sporten-in-groningen flow, get the category and filters from slugs
      const filters = urlSports?.split('_') || []
      const [category, subfilters] = getFiltersAndSubfilters(
        urlCategory,
        filters
      )
      selectedCategory = category
      selectedFilters = subfilters
    }

    if (query) {
      // Get the proper filters when there are query params
      if (query.order) {
        setOrder(query.order)
      }
      setPage(query.page || 1)
      // Listing mode
      if (query.mode) {
        setMode(query.mode)
        setTab(query.mode)
        queryMode = query.mode
      } else {
        // Default to "list" tab
        setTab('list')
        queryMode = 'list'
      }
      if (query.distance && query.postal_code) {
        queryDistance = {
          distance: query.distance,
          postalCode: query.postal_code,
        }
        setDistancePostalcode(queryDistance)
      }
      if (query.additionals) {
        // Get the additional filters
        if (typeof query.additionals == 'string') {
          // Only one
          let add = query.additionals
          const title = add.includes('__') ? add.split('__')[1] : add
          queryAdditionals.push({
            id: title,
            name: title,
            text: title,
            value: add,
          })
        } else {
          // Multiple
          query.additionals?.forEach((add) => {
            const title = add.includes('__') ? add.split('__')[1] : add
            queryAdditionals.push({
              id: title,
              name: title,
              text: title,
              value: add,
            })
          })
        }
        setAdditionals(queryAdditionals)
      }
      if (query.attributes) {
        // Get attribute filters
        const attrs =
          typeof query.attributes === 'string'
            ? [query.attributes]
            : query.attributes
        queryAttributes = getSelectedAttributes(attrs)
        setAttributes(queryAttributes)
      }
      if (!urlCategory && (query.category || query.filters)) {
        // Get category and filters (sports) for locations index
        const [category, subfilters] = getFiltersAndSubfilters(
          query.category,
          query.filters
        )
        selectedCategory = category
        selectedFilters = subfilters
      }
    }
    setCategory(selectedCategory)
    setFilters(selectedFilters)
    setPagetype(pagetype)
    setHost(host)
    const getMount = async () => {
      await getInitialData({
        page: query?.page || 1,
        pagetype: pagetype,
        host: host,
        filters: selectedFilters,
        category: selectedCategory || null,
        additionals: queryAdditionals || null,
        attributes: queryAttributes || null,
        mode: queryMode,
        distance: queryDistance,
      })
      setLoading(false)
      setMounted(true)
      setPreviousPath(asPath)
    }
    getMount()
  }, [])

  const getSubfiltersUrl = (string) => {
    // Get a list of selected subfilters from ths subfilters string
    if (!string) {
      return []
    }
    if (string.includes('?')) {
      string = string.split('?')[0]
    }
    return string.includes('_') ? string.split('_') : [string]
  }

  const handlePathChangeRequest = () => {
    setLoading(true)
    const pagetype = isLocation ? 'location' : 'club'
    let selectedFilters = []
    let queryAdditionals = []
    let queryAttributes = []
    let queryDistance = null
    let queryMode = ''
    let selectedCategory = null

    // Get category and subfilters from URL
    const splittedPath = asPath.split('/')
    let urlCat = splittedPath?.length > 1 ? splittedPath[2] : ''
    if (urlCat && urlCat.includes('?')) {
      urlCat = urlCat.split('?')[0]
    }
    const urlSubfilters = splittedPath?.length > 2 ? splittedPath[3] : ''
    let subfilterItems = []
    if (urlSubfilters) {
      subfilterItems = getSubfiltersUrl(urlSubfilters)
    }

    if (query) {
      // Get the proper filters when there are query params
      if (query.order) {
        setOrder(query.order)
      }
      if (query.page) {
        setPage(query.page)
      }
      // Listing mode
      if (query.mode) {
        setMode(query.mode)
        setTab(query.mode)
        queryMode = query.mode
      } else {
        // Default to "list" tab
        setTab('list')
        queryMode = 'list'
      }
      if (query.distance && query.postal_code) {
        queryDistance = {
          distance: query.distance,
          postalCode: query.postal_code,
        }
        setDistancePostalcode(queryDistance)
      }
      if (query.additionals) {
        // Get the additional filters
        if (typeof query.additionals == 'string') {
          // Only one
          let add = query.additionals
          const title = add.includes('__') ? add.split('__')[1] : add
          queryAdditionals.push({
            id: title,
            name: title,
            text: title,
            value: add,
          })
        } else {
          // Multiple
          query.additionals?.forEach((add) => {
            const title = add.includes('__') ? add.split('__')[1] : add
            queryAdditionals.push({
              id: title,
              name: title,
              text: title,
              value: add,
            })
          })
        }
        setAdditionals(queryAdditionals)
      }
      if (query.attributes) {
        // Get attribute filters
        const attrs =
          typeof query.attributes === 'string'
            ? [query.attributes]
            : query.attributes
        queryAttributes = getSelectedAttributes(attrs)
        setAttributes(queryAttributes)
      }
      if (isLocation && (query.category || query.filters)) {
        // Get category and filters (sports) for locations index
        const [category, subfilters] = getFiltersAndSubfilters(
          query.category,
          query.filters
        )
        selectedCategory = category
        selectedFilters = subfilters
      } else if (!isLocation && (urlCat || subfilterItems)) {
        // Get category and filters (sports) based on route params
        const [category, subfilters] = getFiltersAndSubfilters(
          urlCat,
          subfilterItems
        )
        selectedCategory = category
        selectedFilters = subfilters
      }
    }
    setCategory(selectedCategory)
    setFilters(selectedFilters)
    setPagetype(pagetype)
    setHost(host)
    const getNewData = async () => {
      await getData({
        pagetype: pagetype,
        host: host,
        filters: selectedFilters,
        category: selectedCategory || null,
        additionals: queryAdditionals || null,
        attributes: queryAttributes || null,
        mode: queryMode,
        distance: queryDistance,
      })
      setLoading(false)
    }
    getNewData()
  }

  React.useEffect(() => {
    if (mounted && asPath !== previousPath) {
      setPreviousPath(asPath)
      handlePathChangeRequest()
    }
  }, [mounted, asPath])

  const CTA = callToActionMap[ctaVariant]

  const data = (isLocation ? locations : clubs)?.state || {}
  const totalCount = data.count
  const currentPageMin = 1 + (page - 1) * perPage
  const currentPageMax = Math.min(page * perPage, totalCount)
  const currentResults =
    tab === 'map'
      ? `${totalCount} ${isLocation ? "locaties" : "aanbieders"}`
      : totalCount
      ? `${currentPageMin} - ${currentPageMax} van ${totalCount}`
      : ''

  const dynamicFilter =
    filters?.length == 1 ? filters[0] : category ? category : ''

  const allFiltersCount =
    (category ? 1 : 0) + filters?.length ||
    0 + selectedAttributes?.length ||
    0 + selectedAdditionals?.length ||
    0 + (distance && postalCode)
      ? 1
      : 0

  return (
    <>
        {!loading && mounted && (
          <div className="maincontent">
            {getDynamicIntro(dynamicContent, title, text)}
            <div className="options">
              <div className="sorting">
                <Field
                  variant="select"
                  label=""
                  required={false}
                  handleChange={handleOrderChange}
                  id="order"
                  value={order}
                  options={orderOptions}
                  width="small"
                />
              </div>
              <div className="summary">
                <ToggleButton
                  onClick={() => {
                    handleModeChange('list')
                    setTab('list')
                  }}
                  className={tab === 'list' ? 'active' : ''}
                >
                  Lijst
                </ToggleButton>

                <ToggleButton
                  onClick={() => {
                    handleModeChange('map')
                    setTab('map')
                  }}
                  className={tab === 'map' ? 'active' : ''}
                >
                  Kaart
                </ToggleButton>
              </div>
            </div>

            <div className="content">
              {
                <div
                  className="mobileFilters"
                  onClick={() => setMobileOpen(!filterOpenMobile)}
                >
                  <span>
                    <span> {allFiltersCount} </span> Beheer filters
                  </span>
                </div>
              }

              <div className="contentnumber">
                <TypographyElement variant="p" component="h2">
                  <div className="pline">{currentResults}</div>
                </TypographyElement>
              </div>

              {tab === 'list' && (
                <MainContent
                  isLoading={isLoading}
                  data={data}
                  page={page}
                  ctaPosition={ctaPosition}
                  cta={ctaData}
                  CTA={CTA}
                  handlePageChange={handlePageChange}
                  isLocation={isLocation}
                />
              )}
              {tab === 'map' && (
                <MapView
                  isLoading={isLoading}
                  data={data}
                  isLocation={isLocation}
                />
              )}

              <div className="extraContent">
                <div className="aboutInfo">
                  {extraContent && <SectionElement {...extraContent} />}
                </div>
                <div className="extraDynamic">
                  <DynamicContent
                    {...dynamicContent}
                    dynamicFilter={dynamicFilter}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {(loading || !mounted) && (
          <div className="maincontent">
            <Loader />
          </div>
        )}
    </>
  )
}
export default ClubResults
